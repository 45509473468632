import React from "react";
import { Helmet } from "react-helmet";
import classes from './Home.module.scss';
import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import AndSvg from "../../components/Shared/And";
import image1 from '../../assets/images/homeHero.jpg';
import imageMob from '../../assets/images/mobileGrid.png';
import skincareImg from '../../assets/images/facialGrid.jpg';
import massageImg from '../../assets/images/massageGrid.jpg';
// import massageImgMobile from '../../assets/images/massageGridMobile.jpg';
import laserImg from '../../assets/images/laserGrid3.jpg';
import browImg from '../../assets/images/eyebrowGrid.jpg';
import cosImg from '../../assets/images/cosmeticGrid.jpg';
import imageEnd from '../../assets/images/rejuv3full.jpg';
import arrow from '../../assets/images/arrowC3.png';

const curve2 = (<textPath href="#curve">&middot; In Orange Beach, AL &middot;</textPath>);
const arrowLink = (<>More<img src={arrow} alt="ph alt" className={classes.arrow} /></>);

const massageT = (
  <>
    <p>Our professional, licensed massage therapists work wonders. Book an appointment today to experience the ultimate in relaxation as they 
    knead the stress, soreness, and tension right out of your body.</p>
    <Link to="/massage-therapy" className={classes.textLink}>{arrowLink}</Link>
  </>
);
const facialT = (
  <>
    <p className={classes.content}>We offer facials and skin care treatments that refresh, revitalize, and improve your skin. We also include 
    home protocols, to help your skin continue to look and feel healthy.</p>
    <Link to="/skin-care" className={classes.textLink}>{arrowLink}</Link>
  </>
);
const cosmeticT = (
  <>
    <p>We offer BOTOX®, JUVÉDERM®, Jeuveau®, and <br className={classes.vBr}/>V Soft Line® Threads. Follow the link below for more information about our options for facial enhancement and line refinement.</p>
    <Link to="/cosmetic-enhancements" className={classes.textLink}>{arrowLink}</Link>
  </>
);
const browT = (
  <>
    <p>We provide non-invasive treatments that give your brows and lashes the perfect look -- darker, thicker, and beautifully full. Our 
    options are quick and painless, and have you bright eyed in no time.</p>
    <Link to="/brow-lash-treatments" className={classes.textLink}>{arrowLink}</Link>
  </>
);
const laserT = (
  <>
    <p>Our TriPollar Apollo laser treatments are a painless and effective way to tighten skin, and reverse the signs of aging
    using medically proven radio-frequency energy.</p>
    <Link to="/laser-treatments" className={classes.textLink}>{arrowLink}</Link>
  </>
);


const home2 = () => {
  return (
    <div className={[classes.Home, classes.alt].join(' ')}>
      <Helmet>
        <title>Facials, Massages, & Spa Treatments in Orange Beach, AL | Rejuvenation Med Spa</title>
        <meta name="description" content="Rejuvenation Med Spa, in Orange Beach, Al, provides spa and cosmetic treatments that relax, revitalize, & boost confidence." />
      </Helmet>
      
      
      <div className={[classes.fullRow, classes.hsec1].join(' ')}>
        <div className={classes.insideAlt}>
          <h1 className={classes.s2}>A refreshing blend of <br/><span>relaxation <AndSvg /> beauty</span></h1>
          <h2>Rejuvenation Med Spa</h2>
        </div>
      </div>

      <div className={[classes.hsFullImg, classes.fi1].join(' ')}>
        <div className={classes.badge}>
          <h2>Rejuvenation<br/> <span>Med Spa</span></h2>

          <div className={classes.curveText}>
            <svg className={classes.curve2} viewBox="0 0 495 500">
              <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
              <text width="500">{curve2}</text>
            </svg>
          </div>
        </div>
        <div className={classes.insideN}><img src={image1} alt="Ladies enjoying spa facials" className={classes.img1} /></div>
      </div>

      <div className={[classes.fullRow, classes.hsecFloat].join(' ')}>
        <div className={[classes.medDef, classes.mediaCust].join(' ')}>
          <div className={[classes.col, classes.left, classes.sig].join(' ')}>
            <p><span className={classes.one}>Contact us today to book</span><br/> <span className={classes.two}> Your next appointment</span></p>
            <div className={classes.cta}><Phone /></div> 
          </div>

          <div className={[classes.col, classes.right, classes.print].join(' ')}>
            <p>
              Located in Orange Beach, Alabama, Rejuvenation Med Spa provides products, services, and treatments that help refresh your body and soul, and boost your confidence.
              We customize our procedures to fit your individual needs, so that you always leave feeling relaxed, vibrant, and beautiful. 
            </p>
          </div>
        </div>
      </div>
      
      <div className={[classes.fullRow, classes.hsec2b].join(' ')}>
        <div className={[classes.fullRow, classes.stackLay, classes.lay2].join(' ')}>
          <img src={imageMob} alt="Spa treatment list at Rejuvenation Med Spa" className={classes.msecImg} />
          
          <div className={classes.slWrapMobile}>
            <ul className={classes.accordion} data-uk-accordion>
              <li className="uk-open">
                <button className={[classes.accTitle, "uk-accordion-title"].join(' ')} href="#"><p className={[classes.hd2, classes.massageHd].join(' ')}><span>Massage</span> Therapy</p></button>
                <div className={[classes.accContent, "uk-accordion-content"].join(' ')}>
                  {massageT}
                </div>
              </li>

              <li>
                <button className={[classes.accTitle, "uk-accordion-title"].join(' ')} href="#"><p className={[classes.hd2, classes.facialHd].join(' ')}><span>Facials</span> &amp; Skin Care</p></button>
                <div className={[classes.accContent, "uk-accordion-content"].join(' ')}>
                  {facialT}
                </div>
              </li>

              <li>
                <button className={[classes.accTitle, "uk-accordion-title"].join(' ')} href="#"><p className={[classes.hd2, classes.cosmeticHd].join(' ')}><span>Cosmetic</span> Enhancements</p></button>
                <div className={[classes.accContent, "uk-accordion-content"].join(' ')}>
                  {cosmeticT}
                </div>
              </li>

              <li>
                <button className={[classes.accTitle, "uk-accordion-title"].join(' ')} href="#"><p className={[classes.hd2, classes.browHd].join(' ')}><span>Brow+Lash</span> Treatments</p></button>
                <div className={[classes.accContent, "uk-accordion-content"].join(' ')}>
                  {browT}
                </div>
              </li>

              <li>
                <button className={[classes.accTitle, "uk-accordion-title"].join(' ')} href="#"><p className={[classes.hd2, classes.laserHd].join(' ')}><span>Laser</span> Treatments</p></button>
                <div className={[classes.accContent, "uk-accordion-content"].join(' ')}>
                  {laserT}
                </div>
              </li>
            </ul>
          </div>

          <div className={classes.slWrap}>
            <div className={[classes.col, classes.sizeS, classes.left, classes.spaced, classes.btmCap, classes.swirl, classes.massageG].join(' ')}>
              <div className={classes.iwrap}>
                <div className={[classes.gridImg, classes.btm].join(' ')}><img src={massageImg} alt="Massage therapy at Rejuvenation Spa" className={classes.gimg1} /></div>
                <div className={classes.caption}>
                  <p className={[classes.hd2, classes.massageHd].join(' ')}><span>Massage</span> Therapy</p>
                  {massageT}
                </div>
              </div>
            </div>

            <div className={[classes.col, classes.sizeL, classes.right, classes.float, classes.tmargin, classes.topCap, classes.facialG].join(' ')}>
              <div className={classes.iwrap}>
                <div className={classes.caption}>
                  <p className={[classes.hd2, classes.facialHd].join(' ')}><span>Facials</span> &amp; Skin Care</p>
                  {facialT}
                </div>
                <div className={[classes.gridImg, classes.top].join(' ')}><img src={skincareImg} alt="Facials & skin care for men and women" className={classes.gimg2} /></div>
              </div>
            </div>

            <div className={classes.clearfix}/>
            <div className={[classes.col, classes.grid100, classes.cosmeticG].join(' ')}>
              <div className={classes.iwrap}>
                <div className={[classes.gridImg, classes.rside].join(' ')}><img src={cosImg} alt="Anti-aging cosmetic treatments" className={classes.gimg1} /></div>
                <div className={classes.caption}>
                  <p className={[classes.hd2, classes.cosmeticHd].join(' ')}><span>Cosmetic</span> Enhancements</p>
                  {cosmeticT}
                </div>
              </div>
            </div>

            <div className={[classes.col, classes.sizeL, classes.left, classes.float, classes.tmargin, classes.topCap, classes.browG].join(' ')}>
              <div className={classes.iwrap}>
                <div className={classes.caption}>
                  <p className={[classes.hd2, classes.browHd].join(' ')}><span>Brow+Lash</span> Treatments</p>
                  {browT}
                </div>
                <div className={[classes.gridImg, classes.top].join(' ')}><img src={browImg} alt="Eyebrow and lash enhancements" className={classes.gimg1} /></div>
              </div>
            </div>

            <div className={[classes.col, classes.sizeS, classes.right, classes.spaced, classes.btmCap, classes.laserG].join(' ')}>
              <div className={classes.iwrap}>
                <div className={[classes.gridImg, classes.btm].join(' ')}><img src={laserImg} alt="Anti-aging laser treatments" className={classes.gimg1} /></div>
                <div className={classes.caption}>
                  <p className={[classes.hd2, classes.laserHd].join(' ')}><span>Laser</span> Treatments</p>
                  {laserT}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
      {/* <div className={[classes.fullRow, classes.banner, classes.bannerQuote, classes.bq1].join(' ')}>
        <div className={[classes.med3Def, classes.qwrap].join(' ')}>
          <p className={classes.quote}><span>”</span> Been coming here for almost 7 years now. Hands down best spa and service on the Gulf Coast. Professional and courteous staff. Wouldn't go anywhere else!!! <span>“</span></p>
          <p className={classes.cat}>- Deborah P.</p>
        </div>
      </div> */}

      <div className={[classes.fullRow, classes.about].join(' ')}>
        <div className={[classes.medDef, classes.mediaCust].join(' ')}>          
          <div className={[classes.col, classes.textSide, classes.grid33].join(' ')}>
            <div className={classes.text}>
              <p>Our team is comprised of local owners, technicians, aestheticians, and physicians who are dedicated to giving guests the best treatments and 
              products available on the medical spa market. </p>
            </div>
          </div>

          <div className={[classes.col, classes.imgSide, classes.grid60].join(' ')}><img src={imageEnd} alt="Entrance area - Rejuvenation Med Spa, Orange Beach" className={classes.img1} /></div>
          <div className={classes.item2}><p className={classes.title}>Because a day at the spa can be even<br/> more relaxing than a day at the beach!</p></div>
        </div>
      </div>
    </div>
  );
}
export default home2;