import React from "react";
// import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Social from "../../components/Social/Social";
// import Drawer from "../Header/Drawer";

// Medical Arts Building 
// 4223 Orange Beach Boulevard, 
// Suite B
// Orange Beach, AL 36561


const footer = (props) => {
  return (
    <footer className={[classes.Footer, classes[props.FooterType], classes.fullRow].join(' ')}>
      <div className={classes.ftWrapServe}>
        <div className={[classes.med2Def, classes.v1].join(' ')}>
          <div className={[classes.col, classes.left].join(' ')}>
            <div className={classes.item}><p>Monday-Friday: 10am-5pm</p></div>
            <div className={classes.item}><p><Phone where="footer" /></p></div>
            <div className={classes.item}><p><Email where="footer" /></p></div>
            <div className={classes.item}><p><Social where="footer" /></p></div>
          </div>

          <div className={[classes.col, classes.right].join(' ')}>
            <div className={classes.item}>
              <p className={classes.cat}>Location: </p>
              <p className={classes.ap1}>4223 Orange Beach Blvd<br/> Suite B <br/>Orange Beach, AL 36561</p>
              <p>
                <a 
                  href="https://www.google.com/maps/dir//Orange+Beach+Medical+Center,+4223+Orange+Beach+Blvd+STE+B,+Orange+Beach,+AL+36561/@30.2860088,-87.6641911,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889a06527d5044f5:0x5b6cb99af9178126!2m2!1d-87.5817898!2d30.2860346?entry=ttu&g_ep=EgoyMDI1MDMyMy4wIKXMDSoASAFQAw%3D%3D" 
                  target="_blank" 
                  rel="noreferrer"
                  className={classes.textLink}>Get Directions
                </a>
              </p>
            </div>
          </div>

          <div className={[classes.col, classes.mid].join(' ')}>
            <div className={[classes.item, classes.i1].join(' ')}>
              <p className={classes.em}>Most services by appointment only. 24 hours notice required to avoid cancellation charges. Prices are subject to change without notice.
              </p>
            </div>
            <div className={[classes.item, classes.i2].join(' ')}>
              <p className={classes.cat}>Botox®, Filler, &amp;<br/> Thread Lift Appointments</p>
              <p>Wednesdays: 2:30pm-8pm (and some Fridays)</p>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.btmFooter].join(' ')}>
        <div className={classes.med4Def}>
          <p className={[classes.copy, classes.hc1].join(' ')}>© {(new Date().getFullYear())} Rejuvenation Med Spa.</p> <p className={[classes.copy, classes.hc2].join(' ')}> Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>
      </div>
    </footer>
  );
}

export default footer;





