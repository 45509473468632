import React from "react";
import { Helmet } from "react-helmet";
import classes from "./Contact.module.scss"
// import { Link } from "react-router-dom";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Social from "../../components/Social/Social";
import image1 from "../../assets/images/contactFacial.jpg";

const contact = () => {
  return (
    <div className={classes.Contact}>
      <Helmet>
        <title>Schedule an Appointment at Rejuvenation Med Spa </title>
        <meta name="description" content="Contact Rejuvenation Med Spa today to book your next massage, facial, or other treatment!" />
      </Helmet>


      <div className={classes.ServiceHeaderSimple}><div className={classes.medDef}>
        {/* <h1>Contact us today to book<br className={classes.hideMe}/> <span>your next appointment</span></h1> */}
        <h1>Contact us today to learn more, <br/><span>or to book your next appointment</span></h1>
      </div></div>

      <div className={classes.fullImg}><div className={classes.insideXl}><img src={image1} alt="Relaxing facial treatments in Orange Beach, Al" /></div></div>

      <div className={[classes.fullRow, classes.customWrap].join(' ')}>
        <div className={classes.XmedDef}>
          <div className={classes.text}>
            <div className={classes.innerText}>
              <div className={[classes.col, classes.grid40, classes.left].join(' ')}>
                <div className={classes.item}><p className={classes.cat}><span>Phone</span></p><p><Phone where="contact" /></p></div>
                <div className={classes.item}><p className={classes.cat}><span>Email</span></p><p><Email where="contact" /></p></div>
                <div className={classes.item}><p className={[classes.cat, classes.long].join(' ')}><span>Follow</span></p><p><Social where="contact" /></p></div>
              </div>

              <div className={[classes.col, classes.grid42, classes.right].join(' ')}>
                <div className={classes.item}><p className={classes.cat}><span>Hours</span></p><p>Monday-Friday: 10am-5pm</p></div>
                <div className={[classes.item, classes.itemAddress].join(' ')}>
                  <p className={[classes.cat, classes.longer].join(' ')}><span>Address</span></p>
                  <p className={classes.p1}>
                    4223 Orange Beach Blvd<br/> 
                    Suite B<br/>
                    Orange Beach, AL 36561
                  </p>
                  <p className={classes.p2}>
                    <a 
                      href="https://www.google.com/maps/dir//Orange+Beach+Medical+Center,+4223+Orange+Beach+Blvd+STE+B,+Orange+Beach,+AL+36561/@30.2860088,-87.6641911,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889a06527d5044f5:0x5b6cb99af9178126!2m2!1d-87.5817898!2d30.2860346?entry=ttu&g_ep=EgoyMDI1MDMyMy4wIKXMDSoASAFQAw%3D%3D" 
                      target="_blank" 
                      rel="noreferrer"
                      className={classes.textLink}>Get Directions</a>
                  </p>
                </div>
              </div>

              <div className={[classes.fullRow, classes.itLast].join(' ')}>
                <p className={[classes.cat, classes.full].join(' ')}>Botox®, Filler, &amp;<br/> Thread Lift Appointments</p>
                <p className={classes.full}>Wednesdays: 2:30pm-8pm<br className={classes.ocShow} /> (and some Fridays)</p>

                <p className={classes.full}>Most services by appointment only.<br className={classes.info} /> 24 hours notice required to avoid cancellation charges. <br className={classes.info} /> Prices are subject to change without notice.</p>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}
export default contact;